// Config
import constants from '@/config/constants'
import conversationConfig from '@/config/conversation'

// Dependencies
import moment from 'moment'
import momentTZ from 'moment-timezone'

// Utils
import { isValueEmpty } from '@/utils'
import { capitalize } from '@/utils/text'

/**
 * Get formatted Date String
 * @param {String} dateStr
 * @param {String} locale
 * @returns {String} Formatted Date
 */
export function getFriendlyDate(dateStr, locale) {
  const currentDate = new Date()
  const givenDate = new Date(dateStr)

  if (givenDate > currentDate) {
    return givenDate.toLocaleDateString(locale.replace('_', '-'), {
      hour: '2-digit',
      minute: '2-digit'
    })
  } else {
    const formatter = new Intl.RelativeTimeFormat(locale.substring(0, 2))
    const ranges = {
      years: 3600 * 24 * 365,
      months: 3600 * 24 * 30,
      weeks: 3600 * 24 * 7,
      days: 3600 * 24,
      hours: 3600,
      minutes: 60,
      seconds: 1
    }
    const secondsElapsed = (givenDate.getTime() - Date.now()) / 1000
    for (const key in ranges) {
      if (ranges[key] < Math.abs(secondsElapsed)) {
        const delta = secondsElapsed / ranges[key]
        return formatter.format(Math.round(delta), key)
      }
    }
  }
}

/**
 * https://stackoverflow.com/questions/44307140/localizing-day-and-month-in-moment-js
 *
 * Get day and month from date
 * @param {Date} date
 * @return {String} formatted date without year
 */
export function getDayAndMonth(date) {
  const formattedDate = moment(date).format('LL')
  return formattedDate
    .replace(moment(date).format('YYYY'), '') // remove year
    .replace(/\s\s+/g, ' ') // remove double spaces, if any
    .trim() // remove spaces from the start and the end
    .replace(/[рг]\./, '') // remove year letter from RU/UK locales
    .replace(/de$/, '') // remove year prefix from PT
    .replace(/b\.$/, '') // remove year prefix from SE
    .trim() // remove spaces from the start and the end
    .replace(/,$/g, '') // remove comma from the end
}

/**
 * Format duration to {00min 00s} format
 * @param {Number} duration
 * @return {String} formatted duration
 */
export function formatDuration(start, end, platformType) {
  const duration = moment.duration(moment(end).diff(moment(start)))
  if (platformType !== constants.PLATFORM_TYPE['phone-twilio']) {
    // @TODO https://trello.com/c/tiFbV5hq/195-user-message-and-assistant-message-should-not-be-saved-at-the-same-time-else-conversation-could-be-0-sec
    duration.add(constants.ADDITION_DURATION_CONV, 's')
  }

  return formatDurationMetric(duration)
}

/**
 * Format duration metric in
 * @param {Number} duration
 * @returns {String} formatted duration in seconds/minutes/hours
 */
export function formatDurationMetric(duration) {
  if (!duration) {
    return '0s'
  }

  if (typeof duration === 'number') {
    duration = moment.duration(duration, 'seconds')
  }

  let format = conversationConfig.DATE_FORMAT_SECONDS
  if (duration.get('hours') > 0) {
    format = conversationConfig.DATE_FORMAT_HOURS
  } else if (duration.get('minutes') > 0) {
    format = conversationConfig.DATE_FORMAT_MINUTES
  }

  return moment.utc(duration.asMilliseconds()).format(format)
}

/**
 * Get the difference between two dates
 * @param {Date} start
 * @param {Date} end
 * @returns {Number} Difference in seconds
 */
export function differenceInSeconds(start, end) {
  return moment(end).diff(moment(start), 'seconds')
}

/**
 * Get the localized list of the weekdays with the numerical representation
 * @param {String} locale
 * @param {Boolean} shouldStartWithMonday
 * @returns {Array<Object>} [{ value: 'Sunday', number: 0 }, ...]
 */
export function getLocalizedWeekdays(locale, shouldStartWithMonday = false) {
  moment.locale(locale)

  // By default, weekdays start with Sunday:
  // [Sunday, Monday, Tuesday, Wednesday, Thursday, Friday, Saturday]
  const weekdays = moment.weekdays()

  // Set the numerical representation of the weekdays explicitly
  const weekdaysObjects = weekdays.map((day, index) => {
    return {
      value: capitalize(day),
      number: index
    }
  })

  // If we want to start with Monday, we need to move Sunday to the end of the array
  // E.g. in the UI, we might want to display the weekdays starting from Monday and not Sunday
  if (shouldStartWithMonday) {
    const sunday = weekdaysObjects.shift()
    weekdaysObjects.push(sunday)
  }

  return weekdaysObjects
}

/**
 * Convert a string time with format HH:mm to a full date object
 * E.g. turns '12:32' into 2021-09-09T12:32:50.215Z
 * @param {String} time HH:mm
 * @param {String} timeZone E.g. 'Europe/Paris'
 * @returns {Date}
 */
export function convertStringTimeToDate(time, timeZone) {
  const [hours, minutes] = time.split(':')
  const date = momentTZ().tz(timeZone).set({ hour: hours, minute: minutes })
  return new Date(date)
}

/**
 * Check if a given date is included in the time range between two other dates
 * @param {Date} date
 * @param {Date} dates.start
 * @param {Date} dates.end
 * @returns {Boolean}
 */
export function isDateBetweenTwoDates(date, { start, end }) {
  return date >= start && date <= end
}

/**
 * Format a stringified date (with timezone) to a stringified UTC date (without timezone)
 * @param {String} dateStr
 * @returns {String}
 */
export function formatDateToUTCString(dateStr) {
  const dateObj = new Date(dateStr)
  return momentTZ
    .tz([dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate()], constants.UNIVERSAL_TIMEZONE)
    .format()
}

/**
 * Convert a value to date
 * For now, it only handles values with the types: String, Number, Date, and Array of those
 * If the value is not one of those types, it returns null
 * @param {String|Number|Date|Array} value
 * @returns {Date|null}
 */
export function convertValueToDate(value) {
  // If the value is empty, no need to go further
  if (isValueEmpty(value)) {
    return null
  }

  // Value is already a Date
  if (value instanceof Date && moment(value).isValid()) {
    return value
  }

  // Value is a string, we need to parse it to a Date
  if (typeof value === 'string') {
    return new Date(value)
  }

  // Value is a number, e.g a timestamp
  if (typeof value === 'number') {
    return new Date(value * 1000)
  }

  // If it is an array of values, we need to parse each value to a Date recursively
  if (Array.isArray(value)) {
    return value.map(date => {
      return convertValueToDate(date)
    })
  }

  return null
}

/**
 * Get date object in UTC format
 * @param {String} dateString
 * @returns {Date} Date in UTC format
 */
export function getDateObjInUTCFormat(dateString) {
  const value = new Date(dateString)
  const offset = value.getTimezoneOffset() // timezone offset in minutes
  // convert to milliseconds for easier manipulation
  const offsetInMilliseconds = offset * constants.ONE_MINUTE_IN_MILLISECONDS
  return new Date(value.getTime() + offsetInMilliseconds)
}

/**
 * Return dateStr at timeZone, formatted into locale, at timeFormat
 * @param {String} dateStr, the date as a string in iso format, example YYYY-MM-DD HH:mm:ss Z
 * @param {String} timeZone the timeZone, example "Europe/Berlin"
 * @param {String} locale, can be "fr" or "fr_FR" or "fr-FR", we only use the language 2 first characters
 * @param {String} timeFormat can be "HH:mm"
 * @returns {String} example: MM/DD/YYYY HH:mm
 */
export function formatDateWithTimezone(dateStr, timeZone, locale, timeFormat) {
  // Getting the date format based on the locale + adding the time format
  const dateTimeFormat = `${moment.localeData(locale.substring(0, 2)).longDateFormat('L')} ${timeFormat}`
  return momentTZ(dateStr).tz(timeZone).format(dateTimeFormat)
}

/**
 * Formate date object based on the provided locale
 * @param {Date} date
 * @param {String} locale
 * @returns {String}
 */
export function formatDateLocale(date, locale) {
  const dateObj = new Date(date)
  return dateObj.toLocaleDateString(locale.replace('_', '-'), {
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  })
}
